import React, { useState } from 'react';
import Modal from 'components/Modal';
import { useToasts } from 'react-toast-notifications';

import { createRef, createOffer } from 'actions';
import CurrencyFormat from 'react-currency-format';

const OfferModal = ({ service, auth }) => {
  const { addToast } = useToasts();
  const [offer, setOffer] = useState({
    fromUser: '',
    toUser: '',
    service: '',
    status: 'pending',
    price: 0,
    time: 0,
    note: '',
  });

  const handleChange = ({ target: { value, name } }) => {
    if (name === 'time') {
      const price = Math.round(value * service.price * 100) / 100;
      return setOffer({ ...offer, [name]: value, price });
    }

    return setOffer({ ...offer, [name]: value });
  };

  const handleSubmit = (closeModal) => {
    const offerCopy = { ...offer };

    offerCopy.fromUser = createRef('profiles', auth.user.uid);
    offerCopy.toUser = createRef('profiles', service.user.id);
    offerCopy.service = createRef('services', service.id);
    offerCopy.time = parseInt(offer.time, 10);

    createOffer(offerCopy).then(
      (_) => {
        closeModal();
        addToast('Offer was succefuly created! (:', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Modal onModalSubmit={handleSubmit} openButtonText="Ikuti Program">
      <div className="field">
        <input
          onChange={handleChange}
          name="note"
          className="input is-large"
          type="text"
          placeholder="Tulis alasan Anda ikut program disini"
          max="5"
          min="0"
        />
        <p className="help">
          Note: Alasan Anda bisa memperbesar kemungkinan Anda diikutkan program
        </p>
      </div>
      <div className="field">
        <input
          onChange={handleChange}
          name="time"
          className="input is-large"
          type="number"
          placeholder="Lama servis yang Anda perlukan ?"
          max="5"
          min="0"
        />
        <p className="help">Masukan waktu dalam jam</p>
      </div>
      <div className="service-price has-text-centered">
        <div className="service-price-title">
          {service.user &&
            `Setelah di accept, "${service.user.fullName}" akan menagih Anda sebesar:`}
        </div>
        <div className="service-price-value">
          <h1 className="title">
            <CurrencyFormat
              value={offer.price}
              displayType="text"
              thousandSeparator={true}
              prefix={'Rp '}
            />
          </h1>
        </div>
      </div>
    </Modal>
  );
};

export default OfferModal;
