import { fetchServices } from 'actions';
import Footer from 'components/Footer';
import ServiceItem from 'components/service/ServiceItem';
import React from 'react';
import { connect } from 'react-redux';

class Services extends React.Component {
  state = {
    services: [],
  };

  componentDidMount() {
    this.props.fetchServices();
  }

  renderServices = (services) =>
    services.map((service) => (
      <ServiceItem
        key={service.id}
        service={service}
        userId={service.user.id}
      />
    ));

  render() {
    const { services } = this.props;
    return (
      <>
        <div className="container">
          <div className="content-wrapper">
            <h1 className="title has-text-centered">Services</h1>
            <div className="columns is-multiline">
              {this.renderServices(services)}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ services: state.services.all });

export default connect(mapStateToProps, { fetchServices })(Services);
