import firebase from 'firebase/app';
import 'firebase/firestore';

const db = firebase
  .initializeApp({
    apiKey: 'AIzaSyCBzqzgFwVcVrs0UkeML3lRKe4FiIc7SY0',
    authDomain: 'bahuku-86f7d.firebaseapp.com',
    databaseURL: 'https://bahuku-86f7d-default-rtdb.firebaseio.com',
    projectId: 'bahuku-86f7d',
    storageBucket: 'bahuku-86f7d.appspot.com',
    messagingSenderId: '350207319086',
    appId: '1:350207319086:web:39c4f2e2cc6d64b016d3bf',
    measurementId: 'G-VGD6V3ESGK',
  })
  .firestore();

export default db;

const { Timestamp } = firebase.firestore;
export { Timestamp };
