/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import RegisterForm from 'components/auth/RegisterForm';
import { register } from 'actions';
import { useToasts } from 'react-toast-notifications';
import onlyGuest from 'components/hoc/onlyGuest';
import { Link } from 'react-router-dom';

// import { withRouter } from 'react-router-dom'

const Register = (props) => {
  const { addToast } = useToasts();

  const registerUser = (userData) => {
    register(userData).then(
      (_) => () => {},
      (errorMessage) =>
        addToast(errorMessage, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        })
    );
  };

  return (
    <div className="auth-page">
      <div className="container has-text-centered">
        <div className="column is-4 is-offset-4">
          <h1 className="title has-text-grey">Register</h1>
          <p className="subtitle has-text-grey">Please Register to proceed.</p>
          <div className="box">
            <figure className="avatar">
              <img src="https://via.placeholder.com/128" alt="Company Logo" />
            </figure>
            <RegisterForm onRegister={registerUser} />
          </div>
          <p className="has-text-grey">
            {/* <a>Sign In With Google</a>&nbsp;·&nbsp; */}
            <Link to={'/login'}>Login</Link> &nbsp;·&nbsp;
            <Link to="/faq">Need Help?</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

// export default withRouter(Register)
export default onlyGuest(Register);
