import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

export default function Footer() {
  return (
    <footer className="footer" id="contact">
      <div className="container has-text-black">
        <div className="columns is-mobile is-multiline">
          <div className="column is-half-mobile is-one-quarter-desktop has-text-centered">
            <h6 className="title is-5 has-text-black">Lokasi kami:</h6>
            <address>
              <strong className="has-text-black">Bahuku.com</strong>
              <br />
              Senayan City <br />
              Third Floor Block A1 South Jakarta <br />
              <i className="fas fa-fw fa-phone"></i>
              <a href="tel:+6281384008007" className="has-text-black">
                {' '}
                (+62) 123-4567-890
              </a>
              <br />
              <i className="fas fa-fw fa-envelope"></i>
              <a href="mailto:admin@bahuku.com" className="has-text-black">
                {' '}
                admin@bahuku.com
              </a>
            </address>
          </div>
          <div className="column is-half-mobile is-one-quarter-desktop">
            <h6 className="title is-5 has-text-black">Menu Utama</h6>
            <ul>
              <li>
                <Link to={'/'} className="has-text-black">
                  Home
                </Link>
              </li>
              <li>
                <Link to={'/services'} className="has-text-black">
                  Services
                </Link>
              </li>
              <li>
                <Link to={'/products'} className="has-text-black">
                  Products
                </Link>
              </li>
              <li>
                <Link to={'/faq'} className="has-text-black">
                  Faq
                </Link>
              </li>
              <li>
                <Link to={'/forum'} className="has-text-black">
                  Forum
                </Link>
              </li>
            </ul>
          </div>
          <div className="column is-half-mobile is-one-quarter-desktop">
            <h6 className="title is-5 has-text-black">Informasi</h6>
            <ul>
              <li>
                <a href="#0" className="has-text-black">
                  Legal information
                </a>
              </li>
              <li>
                <a href="#0" className="has-text-black">
                  Job opportunitis
                </a>
              </li>
              <li>
                <a href="#0" className="has-text-black">
                  F.A.Q
                </a>
              </li>
              <li>
                <a href="#0" className="has-text-black">
                  Media
                </a>
              </li>
              <li>
                <a href="#0" className="has-text-black">
                  Support
                </a>
              </li>
            </ul>
          </div>
          <div className="column is-half-mobile is-one-quarter-desktop ">
            <h6 className="title is-5 has-text-black">Follow us</h6>
            <ul>
              <li>
                <a href="#0" className="has-text-black">
                  <i className="fab fa-fw fa-facebook"></i> Facebook
                </a>
              </li>
              <li>
                <a href="#0" className="has-text-black">
                  <i className="fab fa-fw fa-instagram"></i> Instagram
                </a>
              </li>
              <li>
                <a href="#0" className="has-text-black">
                  <i className="fab fa-fw fa-twitter"></i> Twitter
                </a>
              </li>
              <li>
                <a href="#0" className="has-text-black">
                  <i className="fab fa-fw fa-youtube"></i> Youtube
                </a>
              </li>
              <li>
                <a href="#0" className="has-text-black">
                  <i className="fab fa-fw fa-linkedin"></i> Linkedin
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p className="has-text-centered">
          &copy; 2022 Bahuku.com. All rights reserved
        </p>
      </div>
    </footer>
  );
}
