/* eslint jsx-a11y/anchor-is-valid: 0 */

import Hero from 'components/Hero';
import ServiceItem from 'components/service/ServiceItem';
import React from 'react';
import { connect } from 'react-redux'; // HOC

import { fetchServices } from 'actions';
import Footer from 'components/Footer';

class Home extends React.Component {
  state = {
    services: [],
  };

  componentDidMount() {
    this.props.fetchServices();
  }

  renderServices = (services) =>
    services.map((service) => {
      return (
        <ServiceItem
          key={service.id}
          service={service}
          userId={service.user.id}
        />
      );
    });

  render() {
    const { services } = this.props;
    return (
      <div>
        <Hero />
        <section className="section section-feature-grey is-medium">
          <div className="container">
            <div className="title-wrapper has-text-centered">
              <h2 className="title is-2">Featured Services </h2>
              <h3 className="subtitle is-5 is-muted">
                Great Power Comes With great Responsability
              </h3>
              <div className="divider is-centered"></div>
            </div>

            <div className="content-wrapper">
              <div className="columns is-multiline">
                {this.renderServices(services)}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ services: state.services.all });

export default connect(mapStateToProps, { fetchServices })(Home);
