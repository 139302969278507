/* eslint jsx-a11y/anchor-is-valid: 0 */

import { Link } from 'react-router-dom';
import { getUserProfile } from 'api/auth';
import { useEffect, useState } from 'react';

const ServiceItem = (props) => {
  const { service, children, className, noButton, userId } = props;
  const [name, setName] = useState('');

  const shortText = (text, maxLength) => {
    if (!text) {
      return ' ';
    }
    if (text.length <= maxLength) {
      return text;
    }

    return text.substr(0, maxLength) + '...';
  };

  useEffect(() => {
    getUserProfile(userId).then((res) => {
      console.log('RES', res.fullName);
      setName(res.fullName);
    });
  }, [userId]);

  return (
    <div className="column is-one-third-tablet is-one-quarter-desktop">
      <div
        className={`feature-card is-bordered has-text-centered revealOnScroll delay-1 ${className}`}
        data-animation="fadeInLeft"
      >
        <div className="card-title">
          <h4>{shortText(service.title, 50)}</h4>
        </div>
        <div className="icon-text">
          <span className="icon accent-icon">
            <i className="fas fa-user-circle"></i>
          </span>
          <span>{name}</span>
        </div>
        <div className="card-icon">
          <img src={service.image} alt="" />
        </div>
        <div className="card-content">
          <div className="content">
            <p>{shortText(service.description, 29)}</p>
            {children && <div className="card-text">{children}</div>}
          </div>
        </div>
        {!noButton && (
          <footer className="card-footer">
            {/* <div className="card-action"> */}
            <Link
              to={`/services/${service.id}`}
              className="button card-footer-item primary-btn raised px-30"
            >
              Lihat Detail
            </Link>
            {/* </div> */}
          </footer>
        )}
      </div>
    </div>
  );
};

export default ServiceItem;
